import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, Platform, ToastController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';

import { AngularFirestore, AngularFirestoreCollection} from 'angularfire2/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPagesAdmin = [
    {
      title: 'Inicio',
      url: '/app/tabs/home',
      icon: 'home'
    },
    {
      title: 'Baterias',
      url: '/app/tabs/batteries',
      icon: 'battery-charging'
    },
    {
      title: 'Inventario',
      url: '/app/tabs/inventory',
      icon: 'cellular'
    },
    {
      title: 'Ordenes',
      url: '/app/tabs/orders',
      icon: 'basket'
    },
    {
      title: 'Nuestro Local',
      url: '/app/tabs/map',
      icon: 'location'
    },
    {
      title: 'Conózcanos',
      url: '/app/tabs/about',
      icon: 'information-circle'
    }
  ];

  appPages = [
    {
      title: 'Inicio',
      url: '/app/tabs/home',
      icon: 'home'
    },
    {
      title: 'Baterias',
      url: '/app/tabs/batteries',
      icon: 'battery-charging'
    },
    {
      title: 'Nuestro Local',
      url: '/app/tabs/map',
      icon: 'location'
    },
    {
      title: 'Conózcanos',
      url: '/app/tabs/about',
      icon: 'information-circle'
    }
  ];

  loggedIn = false;
  dark = true;
  username: string;
  users: Array<any>;
  admin = false; 

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController
  ) {
    this.initializeApp();    
  }

  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();
    this.getLoginUser();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
    return this.updateLoggedInStatus(loggedIn);
    });
  }

  getLoginUser() {
   // if (this.loggedIn) {
      this.userData.getUsername().then(res => {
      this.username = res;
      if (this.username == 'admin@bateriascasanare.com') {
        this.admin = true;
      } else {
        this.admin = false;
      }
    })
   //}
   //this.username = window.localStorage.getItem("user");
   //this.users = JSON.parse(this.username);
   //this.users.forEach(function(user) {
   //    return user.email;   
   //});
   
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/app/tabs/home');
    });
  }
}
