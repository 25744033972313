import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

import { Router } from  "@angular/router";
import { auth } from  'firebase/app';
import { AngularFireAuth } from  "@angular/fire/auth";
import { User } from  'firebase';
import { AlertController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class UserData {
  favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
  user: User;

  constructor(
    public storage: Storage, public  afAuth:  AngularFireAuth, private alertCtrl: AlertController
  ) {
    this.afAuth.authState.subscribe(user => {
        if (user){
          this.user = user;
          localStorage.setItem('user', JSON.stringify(this.user));
        } else {
          localStorage.setItem('user', null);
        }
      })
  }

  hasFavorite(sessionName: string): boolean {
    return (this.favorites.indexOf(sessionName) > -1);
  }

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  }

  async showAlert (message: string) {
    const alert = await this.alertCtrl.create({
    message: message,
    buttons: [{ text: "Ok", role: 'cancel' }]
    });
    await alert.present();
  };

  async login(username: string, password: string): Promise<any> {
    

    this.afAuth.auth.signInWithEmailAndPassword(username, password).then(() => {
      return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      return window.dispatchEvent(new CustomEvent('user:login'));
      });
     }, (error) => {
        this.showAlert(error.message);
      });

      //var result = await this.afAuth.auth.signInWithEmailAndPassword(username, password)
      //return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      //  this.setUsername(username);
      //  return window.dispatchEvent(new CustomEvent('user:login'));
      //});
  }

  async signup(username: string, password: string): Promise<any> {
    var result = await this.afAuth.auth.createUserWithEmailAndPassword(username, password)
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      return window.dispatchEvent(new CustomEvent('user:signup'));
    });
  }

  async logout(): Promise<any> {
    await this.afAuth.auth.signOut();
    localStorage.removeItem('user');
    return this.storage.remove(this.HAS_LOGGED_IN).then(() => {
      return this.storage.remove('username');
    }).then(() => {
      window.dispatchEvent(new CustomEvent('user:logout'));
    });
  }

  setUsername(username: string): Promise<any> {
    return this.storage.set('username', username);
  }

  getUsername(): Promise<string> {
    return this.storage.get('username').then((value) => {
      return value;
    });
  }

  isLoggedIn(): Promise<boolean> {
    return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
      return value === true;
    });
  }

  checkHasSeenTutorial(): Promise<string> {
    return this.storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
      return value;
    });
  }
}
